import Vue from 'vue';
import {mapGetters} from 'vuex';
import aosMixin from '@/mixins/aos';

Vue.mixin({
  data() {
    return {
      currentVw: 3,
      currentVh: 3,
      currentVwContainer: 3,
    };
  },
  computed: {
    ...mapGetters(['isMobile', 'isOffline', 'isSafari']),
  },
  mounted() {
    this.currentVw = Math.max(document.documentElement.clientWidth || 0);
    this.currentVh = Math.max(document.documentElement.clientHeight || 0);
    this.currentVwContainer = Math.max(document.getElementsByClassName('base-navbar')[0].firstChild.clientWidth - (this.isMobile ? 60 : 20) || 0);
  },
  methods: {
    getNavbar() {
      return document.getElementsByClassName('base-navbar');
    },
    vh(value = 100) {
      return Math.round(this.currentVh / 100 * value);
    },
    vw(value = 100) {
      return Math.round(this.currentVw / 100 * value);
    },
    vwC(value = 100) {
      return Math.round(this.currentVwContainer / 100 * value);
    },
    srcSet (src, { width, height }) {
      const x1 = this.$img.getImage(src, { modifiers: { width, height, quality: 100 } }).url;
      const x2 = this.$img.getImage(src, { modifiers: { width: width * 2, height: height * 2, quality: 100 } }).url;
    
      return `${x1}, ${x2} 2x`;
    },
  },
});

Vue.filter('price', (value) => {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
});

Vue.mixin(aosMixin);