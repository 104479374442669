export const SectionAbout = () => import('../../src/components/section/about.vue' /* webpackChunkName: "components/section-about" */).then(c => wrapFunctional(c.default || c))
export const SectionMain = () => import('../../src/components/section/main.vue' /* webpackChunkName: "components/section-main" */).then(c => wrapFunctional(c.default || c))
export const SectionMasters = () => import('../../src/components/section/masters.vue' /* webpackChunkName: "components/section-masters" */).then(c => wrapFunctional(c.default || c))
export const SectionOffers = () => import('../../src/components/section/offers.vue' /* webpackChunkName: "components/section-offers" */).then(c => wrapFunctional(c.default || c))
export const SectionPrograms = () => import('../../src/components/section/programs.vue' /* webpackChunkName: "components/section-programs" */).then(c => wrapFunctional(c.default || c))
export const SectionQuote = () => import('../../src/components/section/quote.vue' /* webpackChunkName: "components/section-quote" */).then(c => wrapFunctional(c.default || c))
export const SectionRequest = () => import('../../src/components/section/request.vue' /* webpackChunkName: "components/section-request" */).then(c => wrapFunctional(c.default || c))
export const BaseArrow = () => import('../../src/components/base/arrow.vue' /* webpackChunkName: "components/base-arrow" */).then(c => wrapFunctional(c.default || c))
export const BaseButton = () => import('../../src/components/base/button.js' /* webpackChunkName: "components/base-button" */).then(c => wrapFunctional(c.default || c))
export const BaseCheckbox = () => import('../../src/components/base/checkbox.vue' /* webpackChunkName: "components/base-checkbox" */).then(c => wrapFunctional(c.default || c))
export const BaseFooter = () => import('../../src/components/base/footer.vue' /* webpackChunkName: "components/base-footer" */).then(c => wrapFunctional(c.default || c))
export const BaseInput = () => import('../../src/components/base/input.vue' /* webpackChunkName: "components/base-input" */).then(c => wrapFunctional(c.default || c))
export const BaseLogo = () => import('../../src/components/base/logo.vue' /* webpackChunkName: "components/base-logo" */).then(c => wrapFunctional(c.default || c))
export const BaseModalMobile = () => import('../../src/components/base/modal-mobile.vue' /* webpackChunkName: "components/base-modal-mobile" */).then(c => wrapFunctional(c.default || c))
export const BaseModal = () => import('../../src/components/base/modal.vue' /* webpackChunkName: "components/base-modal" */).then(c => wrapFunctional(c.default || c))
export const BaseNavbar = () => import('../../src/components/base/navbar.vue' /* webpackChunkName: "components/base-navbar" */).then(c => wrapFunctional(c.default || c))
export const BaseSection = () => import('../../src/components/base/section.vue' /* webpackChunkName: "components/base-section" */).then(c => wrapFunctional(c.default || c))
export const BaseSelect = () => import('../../src/components/base/select.vue' /* webpackChunkName: "components/base-select" */).then(c => wrapFunctional(c.default || c))
export const BaseTooltip = () => import('../../src/components/base/tooltip.vue' /* webpackChunkName: "components/base-tooltip" */).then(c => wrapFunctional(c.default || c))
export const IconArrow = () => import('../../src/components/icon/arrow.vue' /* webpackChunkName: "components/icon-arrow" */).then(c => wrapFunctional(c.default || c))
export const IconBurger = () => import('../../src/components/icon/burger.vue' /* webpackChunkName: "components/icon-burger" */).then(c => wrapFunctional(c.default || c))
export const IconCheck = () => import('../../src/components/icon/check.vue' /* webpackChunkName: "components/icon-check" */).then(c => wrapFunctional(c.default || c))
export const IconClose = () => import('../../src/components/icon/close.vue' /* webpackChunkName: "components/icon-close" */).then(c => wrapFunctional(c.default || c))
export const IconDropArrow = () => import('../../src/components/icon/drop-arrow.vue' /* webpackChunkName: "components/icon-drop-arrow" */).then(c => wrapFunctional(c.default || c))
export const IconX = () => import('../../src/components/icon/icon-x.vue' /* webpackChunkName: "components/icon-x" */).then(c => wrapFunctional(c.default || c))
export const IconTelegram = () => import('../../src/components/icon/telegram.vue' /* webpackChunkName: "components/icon-telegram" */).then(c => wrapFunctional(c.default || c))
export const IconWaFilled = () => import('../../src/components/icon/wa-filled.vue' /* webpackChunkName: "components/icon-wa-filled" */).then(c => wrapFunctional(c.default || c))
export const IconWa = () => import('../../src/components/icon/wa.vue' /* webpackChunkName: "components/icon-wa" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
