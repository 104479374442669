const state = () => ({
  ua: /iPhone|iPad|iPod|Android|Windows Phone/.test(typeof window !== 'undefined' ? window.navigator.userAgent : ''),
  safari: false,
  mobile: false,
  offline: false,
});

const mutations = {
  setMobile(state, value) {
    state.mobile = value;
  },
  setSafari(state, value) {
    state.safari = value;
  },
  setOffline: (state, value) => {
    state.offline = value;
  },
};

const getters = {
  isMobile(state) {
    return state.mobile;
  },
  isSafari(state) {
    return state.safari;
  },
  isOffline(state) {
    return state.offline;
  },
};

const actions = {};

export default {
  strict: false,
  state,
  getters,
  mutations,
  actions,
};